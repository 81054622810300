.custombutton {
    padding: 12px 170px !important;
    border-radius: 12px !important;
    border: none !important;
    background: linear-gradient(90deg, #FF648A 0%, #F0003B 103.48%);
    box-shadow: rgba(208, 10, 59, 0.45) 0px 20px 20px -10px
}

@media only screen and (max-width: 600px) {
    .custombutton {
        padding: 12px 90px !important;
    }
}