@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {}

body {
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

/* Common css-start */
.py-100 {
  padding: 100px 0;
}

.py-50 {
  padding: 50px 0;
}

.py-30 {
  padding: 30px 0;
}

.theme-color {
  color: #F0003B;
}

a {
  text-decoration: none;
}

ul li {
  list-style: none;
}

.fw-semibold {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400 !important;
}


textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
select:focus,
/* button:focus, */
button:active,
label:focus,
.btn:active,
.btn.active,
.form-control {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.slick-slide {
  outline: none !important
}

.cursor-pointer {
  cursor: pointer;
}

label {
  color: #000000;
  font-size: 13px;
}

.form-control,
.form-select {
  font-weight: 600 !important;
  border: none !important;
  border-bottom: 2px solid #AAAAAA !important;
  border-radius: 0 !important;
}

.form-control:focus,
.form-select:focus {
  border-bottom: 2px solid #FF648A !important;
}

.form-control::placeholder,
.form-select::placeholder {
  font-weight: 400;
}

.form-check-input {
  width: 1.2em !important;
  height: 1.2em !important;
  margin-top: 0.2em !important;
  border: 2px solid #555555 !important;
}

.form-check-input:focus {
  border: 2px solid #555555 !important;
}

.form-check-input:checked {
  background-color: #F0003B !important;
  border-color: #F0003B !important;
}

.form-check-label {
  font-size: 15px;
  color: #555555;
  margin: 0px 8px;
}

.selectplaceholder {
  font-weight: 400 !important;
  color: #777777 !important;
}

.modal-content {
  border-radius: 10px !important;
}

.pinkshow {
  background-color: #F0003B;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down .modal-content {
    height: 78%;
    border: 0;
    border-radius: 0;
  }
}

.progress-bar {
  background-color: #F0003B;
  border-radius: 18px;
}

.progress {
  height: 5px;
  background-color: rgba(255, 100, 138, 0.25);
  border-radius: 18px;
}

.customcontainer {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.accordion-item {
  border: none;
}

.accordion-button:focus {
  box-shadow: none !important;
  outline: 0px !important;
  -webkit-appearance: none;
}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #ffffff;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.text-grey {
  color: #555555;
}

.text-lightgrey {
  color: #777777;
}

.text-darkgrey {
  color: #333333;
}

.bg-lightgrey {
  background-color: #D9D9D9;
}

.fs-12 {
  font-size: 12px;
}

.fs-17 {
  font-size: 17px;
}

.react-tabs__tab--selected {
  background-color: #E8E8E8;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.dropdown-toggle::after {
  content: none;
  vertical-align: 0;
}

.rc-slider-handle {
  background: linear-gradient(180deg, #FF648A 0%, #F0003B 100%);
  border: none;
  width: 20px;
  height: 20px;
  opacity: 1;
  margin-top: -9px;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: none !important;
  border-color: #FF648A;
}

.rc-slider-rail {
  height: 2px;
}

.rc-slider-track {
  background: linear-gradient(180deg, #FF648A 0%, #F0003B 100%);
  height: 2px;
}

#basic-nav-dropdown {
  padding: 0;
}

/* Date-Picker */

.react-datepicker__input-container input {
  width: 100%;
  border: none;
  border-bottom: 2px solid #AAAAAA;
  padding-left: 12px;
}

.react-datepicker__header {
  background: mistyrose;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: mistyrose;
}

.react-datepicker__navigation {
  top: 8px;
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  background: transparent;
  border: none;
  border-bottom: 1px solid #aaaaaa8c;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.react-datepicker__year-select {
  width: 50px;
  text-align: center;
}

.react-datepicker__month-select option,
.react-datepicker__year-select option {
  background-color: mistyrose;
}

.react-datepicker__year-select::-webkit-scrollbar {
  display: none;
}

.react-datepicker__day--outside-month {
  color: #AAAAAA;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #FF4E6A !important;
  color: white;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  border: none !important;
  background-color: #E6E6E6 !important;
}

.css-wjh20t-MuiPagination-ul li:first-child .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root,
.css-wjh20t-MuiPagination-ul li:last-child .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  background-color: transparent !important;
}

/* Common css-end */

.matches-containt {
  width: 25rem;
}