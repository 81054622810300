/* s19 */
.chatCards {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px !important;
}

.chatCards .card-body {
  padding: 0rem 0rem;
}

.cardsLine {
  height: 810px;
  background: #ffffff;
  border: 1px solid #d5d5d5;
  border-radius: 20px 0px 0px 20px;
  margin-top: -1rem !important;
  margin-bottom: -17px;
  margin-left: -4px;
}

.chatLine1 {
  width: 50%;
  height: 12.65px;
  border-radius: 10px;
  background: #f2f2f2;
}

.chatLine2 {
  width: 75%;
  height: 12.65px;
  border-radius: 10px;
  background: #f2f2f2;
}

.circleImg {
  width: 64.05px;
  height: 64.05px;
  background: linear-gradient(180deg, #e7c656 0%, #ad8d1e 100%);
}

.chatCrown {
  width: 37.87px;
  height: 30.7px;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
}

.chatText {
  font-size: 14px;
  line-height: 17px;
  color: #555555;
  font-family: "Inter", sans-serif;
}

.upgradeNowBtn {
  background-image: linear-gradient(90deg, #ff648a 0%, #f0003b 103.48%);
  color: #ffffff;
  padding: 12px 60px;
  border-radius: 10px;
  border: none;
}

.chatMessageIcon svg {
  color: #777777;
}

.chatPage {
  display: block;
}

.chatMobilePage {
  display: none;
}

@media (max-width: 1199px) {
  .chatLine1 {
    width: 90px;
    height: 12.65px;
    border-radius: 10px;
    background: #f2f2f2;
  }

  .chatLine2 {
    width: 130px;
    height: 12.65px;
    border-radius: 10px;
    background: #f2f2f2;
  }
}

@media (max-width: 1023px) {
  .chatPage {
    display: none;
  }

  .chatMobilePage {
    display: block;
  }
}

/* s19 */

/* s20 */
/*chat SideBar */
.chatProfile {
  height: 80vh;
  border: 1px solid #d5d5d5;
  border-radius: 10px 0px 0px 10px;
  overflow-y: scroll;
}

.profileIcon {
  width: 74.06px;
  height: 74.06px;
}

.profileName {
  font-size: 15px;
}

.chatMessage {
  font-size: 12px;
  color: #777777;
}

.chatMessageTime {
  font-size: 11px;
  color: #777777;
}

/*chat SideBar */

/*chat Messenger */

.chatMessageBody {
  height: 80vh;
  background: #f0f0f0;
  border: 1px solid #d5d5d5;
  border-radius: 0px 10px 0px 0px;
}

.chatProfileHeader {
  height: 73px;
  background: #f0003b;
  border-radius: 0px 10px 0px 0px;
}

.chatProfileName {
  font-size: 18px;
  color: #ffffff;
}

.chatProfileStatus {
  font-size: 12px;
  color: #ffffff;
}

.chatMessages {
  display: flex;
  flex-direction: column;
  /* height: 620px; */
  overflow-y: scroll;
  padding: 10px;
  flex-flow: column-reverse;
}

.receiverdiv {
  background: #ffffff;
  box-shadow: 0px 3px 4px #dbdbdb;
  border-radius: 10px;
  width: fit-content;
  max-width: 350px;
}

/* .receivertext {
  font-size: 14px;
  height: auto;
  flex-direction: column;
} */

.senderdiv {
  background: linear-gradient(180deg, #ff648a 0%, #f0003b 100%);
  box-shadow: 0px 3px 4px #dbdbdb;
  border-radius: 10px;
  max-width: 350px;
  margin-left: auto;
  width: fit-content;
}

.messageFooter {
  /* height: 69px; */
  background: #eaeaea;
}

.messageType {
  font-size: 14px;
  margin-left: 1.5rem !important;
}

.microphone {
  display: none;
}

.arrows {
  display: none;
}

.noConversationText {
  font-size: 25px;
  color: rgb(224, 220, 220);
}

/*chat Messenger */

@media (max-width: 991px) {

  /*chat SideBar */
  .chatProfile {
    height: calc(100vh - 56px);
    border: none;
  }

  /*chat SideBar */

  /*chat Messenger */
  .chatMessageBody {
    height: calc(100vh - 56px);
    background: #ffffff;
    border: none;
  }

  .chatProfileHeader {
    background: #ffffff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  }

  .messageType {
    margin-left: 0rem !important;
  }

  .microphone {
    display: block;
  }

  .arrows {
    display: block;
  }

  .chatProfileName {
    font-size: 18px;
    color: #000000;
  }

  .chatProfileStatus {
    font-size: 12px;
    color: #ff648a;
  }

  /*chat Messenger */
}

/* Photo Viewer */
.closeButton {
  padding: 5px;
  background: #ffffff73;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
}