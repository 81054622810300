.footerText {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500px;
}

@media (max-width: 365px) {
  .footerText {
    font-size: 10px;
    line-height: 17px;
  }
}
