.btnSave {
  background: linear-gradient(90deg, #ff648a 0%, #f0003b 103.48%);
  border-radius: 12px;
  color: #ffffff;
  padding: 20px 20px;
  border: none;
  width: 374px;
  height: 60px;
}
.profileCloseIcon svg {
  left: 15rem !important;
}

@media (max-width: 500px) {
  .btnSave {
    padding: 5px 5px;
    width: 250px;
    height: 60px;
  }
}
