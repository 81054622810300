.toggleSwitchMain {
    position: relative;
    width: 50px;
    display: inline-block;
    text-align: left;
    top: 4px;
}

.togglecheckbox {
    display: none;
}

.togglelabel {
    display: block;
    overflow-x: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
}

.toggleinner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.toggleinner:before,
.toggleinner:after {
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 26px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
}

.toggleinner:before {
    content: "";
    /* content: "YES"; */
    padding-left: 10px;
    background-color: #FFCCD9;
    color: #fff;
}

.toggleinner:after {
    content: "";
    /* content: "NO"; */
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
}

.toggleswitch {
    display: block;
    width: 30px;
    height: 30px;
    background: linear-gradient(180deg, #FF648A 0%, #F0003B 100%);
    position: absolute;
    top: -6px;
    right: 20px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}

.togglecheckbox:checked+.togglelabel .toggleinner {
    margin-left: 0;
}

.togglecheckbox:checked+.togglelabel .toggleswitch {
    right: 0px;
}