.loginlogo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    border-radius: 50%;
    height: 80.11px;
    width: 79px;
}

.signwith {
    padding: 13px;
    border-radius: 50%;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.1)
}