.editProfileImage {
    position: relative;
}

.editProfileImage img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    padding: 3px;
    background-color: white;
}

.editProfileImage svg {
    position: absolute;
    bottom: 5px;
    right: 0;
    background: white;
    border-radius: 50%;
}

.othersCard {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.savebutton {
    margin: 40px 0px;
    background-image: linear-gradient(90deg, #FF648A 0%, #F0003B 103.48%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.25) 0px 4px 6px -2px;
    color: #ffffff;
    padding: 12px 120px;
    border-radius: 7px;
    border: none;
}