/* Page-One */
.heading {
  font-size: 22px;
}

.heading2 {
  font-size: 30px;
}

.circleImage1 {
  left: 2rem;
  height: 80px;
  width: 80px;
}

.circleImage2 {
  left: 6rem;
  height: 80px;
  width: 80px;
}

.circleImage3 {
  left: 10rem;
  height: 80px;
  width: 80px;
}

.circleImageText {
  border: 2px solid #ffffff;
  left: 14rem;
  padding: 25px;
  height: 80px;
  width: 80px;
  font-size: 16px;
}

.paragraphText {
  font-size: 16px;
  line-height: "124.5%";
  font-weight: 300px;
}

.footerText {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500px;
}

.continueBtn {
  background: linear-gradient(90deg, #989898 0%, #757575 103.48%);
  border-radius: 12px;
  color: #ffffff;
  padding: 12px 80px;
  border-radius: 15px;
  border: none;
  width: 230px;
  height: 60px;
}

@media (max-width: 365px) {
  .heading {
    font-size: 23px;
  }

  .heading2 {
    font-size: 27px;
  }

  .circleImage1 {
    left: 2rem;
    height: 60px;
    width: 60px;
  }

  .circleImage2 {
    left: 5rem;
    height: 60px;
    width: 60px;
  }

  .circleImage3 {
    left: 8rem;
    height: 60px;
    width: 60px;
  }

  .circleImageText {
    left: 11rem;
    padding: 20px;
    height: 60px;
    width: 60px;
    font-size: 10px;
  }

  .paragraphText {
    font-size: 10px;
  }

  .footerText {
    font-size: 10px;
    line-height: 17px;
  }
}

@media (max-width: 310px) {
  .circleImage1 {
    left: 3rem;
    height: 50px;
    width: 50px;
  }

  .circleImage2 {
    left: 5rem;
    height: 50px;
    width: 50px;
  }

  .circleImage3 {
    left: 7rem;
    height: 50px;
    width: 50px;
  }

  .circleImageText {
    border: 2px solid #ffffff;
    left: 9rem;
    padding: 20px;
    height: 50px;
    width: 50px;
  }

  .circleText {
    font-size: 16px;
  }
}

/* Page-One */

/* Page-Two */
.customContainer {
  position: relative;
  max-width: 1050px;
  background: white;
  /* height: 700px; */
  height: 90vh;
  overflow-y: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.customContainer::-webkit-scrollbar {
  display: none;
}

.connectBtn {
  background: linear-gradient(90deg, #ff648a 0%, #f0003b 103.48%);
  border-radius: 12px !important;
  border: none !important;
  color: #ffffff;
  padding: 12px 80px;
  width: 298px;
  height: 60px;
  box-shadow: rgba(208, 10, 59, 0.45) 0px 20px 20px -10px
}

.headingText {
  font-size: 16px;
  text-align: center;
}

.headingText2 {
  font-size: 16px;
  right: 80px;
}

.customCheckMark {
  top: 7px;
  right: 7px;
  color: #0fa958;
}

.customCheckIcon {
  background: #ffffff;
}

@media (max-width: 1900px) {
  .customCheckMark {
    color: #ffffff;
  }

  .customCheckIcon {
    background: #14d111;
  }

  .customContainer {
    /* height: 590px; */
  }
}

@media (max-width: 1399px) {
  .customContainer {
    max-width: 700px;
  }
}

@media (max-width: 992px) {
  .customContainer {
    max-width: 530px;
  }

  .headingText {
    font-size: 14px;
  }

  .headingText2 {
    font-size: 14px;
    margin-top: -3rem;
    margin-right: -3rem;
    border-bottom: 1px solid white;
  }

  .connectBtn {
    padding: 7px 50px;
    width: 250px;
    height: 50px;
  }

}

@media (max-width: 767px) {
  .customContainer {
    max-width: 360px;
  }
}

@media (max-width: 576px) {
  .customContainer {
    max-width: 200px;
  }

  .headingText2 {
    margin-top: -4rem;
  }

  .connectBtn {
    padding: 5px 15px;
    width: 150px;
    height: 50px;
  }
}

@media (max-width: 375px) {
  .customContainer {
    max-width: 200px;
    /* height: 450px; */
  }
}

/* Page-Two */