.premimumBackCurv {
    width: 125%;
    height: 50vh;
    background: linear-gradient(91.16deg, #FF648A -60.1%, #F0003B 90.47%);
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-radius: 100vw/20vw;
    position: inherit;
    left: -12vw;
    z-index: -1;
}

.premimumCardsContainer {
    margin-top: -45vh;
    max-width: 1600px;

}

@media only screen and (min-height: 1000px) {
    .premimumCardsContainer {
        margin-top: -30vh;
    }
}

.premimumCard {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 15px !important;
    max-width: 18rem !important;
    min-width: 16rem !important;
    margin-bottom: 5px;
}

.hrline {
    height: 1px;
    background: linear-gradient(270deg, rgba(217, 217, 217, 0) -4.91%, #C2C2C2 57.15%, rgba(217, 217, 217, 0) 104.47%);
    border-radius: 5px;
    margin: 25px 0px;
}

.continueBtn {
    margin: 15px 0px 10px;
    background-image: linear-gradient(90deg, #FF648A 0%, #F0003B 103.48%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.25) 0px 4px 6px -2px;
    color: #ffffff;
    padding: 12px 60px;
    border-radius: 7px;
    border: none;
}


.paymentCards {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;
}

.accordianItems {
    margin: 10px 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;
    border-radius: 10px !important;
    padding: 7px;
}

.accordianHeader {
    border-radius: 10px !important;
    border-radius: 10px !important;
}

.accordianHeader button {
    border-radius: 10px !important;
    border-radius: 10px !important;
}