.userBox {
  width: 100%;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.userBoxTop {
  width: 102%;
  height: 140px;
  background: linear-gradient(92.73deg, #ff648a 2.62%, #f0003b 91.2%);
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-radius: 650px/70px;
  position: inherit;
  left: -8px;
  z-index: -1;
}

.userBoxUserProfile {
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  margin-top: -50px;
  z-index: 1;
}

.userBoxUserImage {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  padding: 3px;
  background-color: white;
}

.userBoxProfileicon {
  border-width: 2px;
  height: 80%;
  border-style: solid;
  border-image: linear-gradient(
      to bottom,
      rgba(163, 163, 163, 0) 10.42%,
      #dcdcdc 53.12%,
      rgba(217, 217, 217, 0)
    )
    1 100%;
}

.profileOption {
  padding: 20px 0;
  margin: 25px;
  border-radius: 20px;
  border: 2px solid #b3b3b3;
}

.profileBtn {
  background-image: linear-gradient(90deg, #ff648a 0%, #f0003b 103.48%);
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  color: #ffffff;
  padding: 12px 80px;
  border-radius: 15px;
  border: none;
}

.userActivityBox {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.ads {
  height: 466px;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  padding: 100px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification {
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  padding: 25px;
  border-radius: 20px;
}
.activeplan {
  background-image: linear-gradient(90deg, #ff648a 0%, #f0003b 103.48%);
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  color: white;
  padding: 15px 30px;
  border-radius: 15px;
  border: none;
}

.circleImg {
  width: 64.05px;
  height: 64.05px;
  background: linear-gradient(180deg, #e7c656 0%, #ad8d1e 100%);
}
.premiumCrown {
  width: 37.87px;
  height: 30.7px;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
}
.premiumText {
  font-size: 14px;
  line-height: 17px;
  color: #555555;
  font-family: "Inter", sans-serif;
}
.upgradeNowBtn {
  background-image: linear-gradient(90deg, #ff648a 0%, #f0003b 103.48%);
  color: #ffffff;
  padding: 12px 60px;
  border-radius: 10px;
  border: none;
}
