.settingTabs {
    padding: 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.settingTabs>small {
    font-size: 12px;
}

.settingTabs li {
    padding: 20px 20px 15px;
    border-bottom: 1px solid #C7C7C7;
    outline: none;
}

.settingTabs li:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.settingTabs li:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

}

.settingCards {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;
    overflow: hidden;
}

.settingCardHeader {
    background: #E8E8E8;
}

.changePasswordCardbody {
    width: 100%;
    max-width: 40rem;
    padding-left: 20px;
}

.changePasswordApplayChanges {
    margin: 35px 0px 10px;
    background-image: linear-gradient(90deg, #FF648A 0%, #F0003B 103.48%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.25) 0px 4px 6px -2px;
    color: #ffffff;
    padding: 12px 120px;
    border-radius: 7px;
    border: none;
}