.subheader_scrollbar {
  overflow: auto !important;
  white-space: nowrap !important;
  padding: 10px 0;
}

.subheader_scrollbar button {
  padding: 11px 20px;
  margin: 0 10px;
}

.subHeaderBtn {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px 20px;
  border: none;
}


.arrows {
  position: absolute;
  top: 41%;
  background: #d9d9d9;
  border-radius: 50%;
  padding: 10px;
  margin: 0;
  border: 2px solid #ffffff;
  cursor: pointer;
  z-index: 1;
}

.profilerightborder {
  width: 100%;
  max-width: 28rem;
  padding: 10px 15px;
  border: 1px solid #aaaaaa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.profilebtn2 {
  background: #ffffff;
  padding: 8px 50px;
  border-radius: 60px;
  font-weight: 500;
  border: 2px solid #f74470;
  box-shadow: -1px -1px 4px 0px rgb(255 100 138 / 64%);
}

.sendbtn {
  /* background: #ffffff; */
  width: 85%;
  color: ghostwhite;
  padding: 8px 50px;
  background-image: linear-gradient(90deg, #e71649 0%, #f0003bb0 103.48%);
  border-radius: 30px;
  font-weight: 500;
  border: none;
  box-shadow: -1px -1px 4px 0px rgb(255 100 138 / 64%);
}

.closeButton {
  padding: 5px;
  background: #ffffff73;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
}

.savebutton {
  margin: 40px 0px;
  background-image: linear-gradient(90deg, #FF648A 0%, #F0003B 103.48%);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.25) 0px 4px 6px -2px;
  color: #ffffff;
  padding: 12px 120px;
  border-radius: 7px;
  border: none;
}


/*  */
/* new design  */
/*  */


/* .subheader_scrollbar {
  overflow: auto !important;
  white-space: nowrap !important;
  padding: 10px 0;
}

.subheader_scrollbar button {
  padding: 11px 20px;
  margin: 0 25px;
}

@media only screen and (max-width: 1200px) {
  .subheader_scrollbar button {
    padding: 11px 20px;
    margin: 0 10px;
  }
} */

/* .subHeaderBtn {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 5px 25px;
  border: none;
} */

.matchesCardText {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 4.71%, #000000 100%);
  position: absolute;
  bottom: 0;
  border-radius: 20px;
}

.acceptbtn {
  width: 85%;
  color: white;
  padding: 8px 50px;
  background: linear-gradient(180deg, #F1BE3A 0%, #D28005 100%);
  border-radius: 30px;
  font-weight: 500;
  border: none;
  box-shadow: -1px -1px 4px 0px rgb(255 100 138 / 64%);
}

.sendbtn {
  /* background: #ffffff; */
  width: 85%;
  color: white;
  padding: 8px 0px;
  background-image: linear-gradient(90deg, #FF648A 0%, #F0003B 103.48%);
  border-radius: 30px;
  font-weight: 500;
  border: none;
  box-shadow: -1px -1px 4px 0px rgb(255 100 138 / 64%);
}

.editProfileImage img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  padding: 3px;
  background-color: white;
}

.othersCard {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.unlockMorePhotos {
  padding: 5px;
  border-radius: 20px;
  border: 2px solid #FF648A;
  background-color: #ffffff;
}

.circleImg {
  width: 64.05px;
  height: 64.05px;
  background: linear-gradient(180deg, #e7c656 0%, #ad8d1e 100%);
}

.chatCrown {
  width: 37.87px;
  height: 30.7px;
  filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.2));
}
