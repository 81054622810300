/* header-start */
.lheader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
}

.headerbtn1 {
    background-color: #0000004d;
    color: #ffffff;
    padding: 10px 30px;
    border-radius: 10px;
    border: none;
}

.headerbtn2 {
    background-image: linear-gradient(to right, #fd567f 0%, #f3134a 100%);
    color: #ffffff;
    padding: 10px 30px;
    border-radius: 10px;
    border: none;
}

/* header-end */

/* slider-start */
.sliderbg {
    height: 700px;
    padding: 100px 0;
    width: 100%;
    /*  */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-shadow: .1em .1em 0 hsl(200 50% 30%);
}

/* slider-end */

/* comunity-start */
.comunitybg {
    background-color: #ffe9ee;
}

.comunityselect {
    width: 80%;
    padding: 10px 5px;
    border: 3px solid #b3b3b3;
    border-radius: 10px;
    color: #b3b3b3;
    font-weight: 500;
}

.comunitybtn {
    background-image: linear-gradient(to right, #fd567f 0%, #f3134a 100%);
    color: #ffffff;
    padding: 12px 100px;
    border-radius: 30px;
    border: none;
}

/* Community-End */

/* Regiser-start */
.registerbg {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
}

.registerbtn {
    background-image: linear-gradient(to right, #fd567f 0%, #f3134a 100%);
    color: #ffffff;
    padding: 12px 50px;
    border-radius: 10px;
    border: none;
}

/* Regiser-end */

/* matrimony-card-start */
.matrimonyCard {
    width: 23rem;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.matrimonyCard img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.registerbtn2 {
    background-image: linear-gradient(to right, #fd567f 0%, #f3134a 100%);
    color: #ffffff;
    padding: 12px 50px;
    border-radius: 25px;
    border: none;
}
.mainprofile{
    padding: 100px 0px 0px; 
}

.gallerytopic span {
    margin: 0 15px;
    transition: 0.2s;
}

.gallerytopic span:focus {
    border-bottom: 4px solid #f3134a;
}

.gallerymenu span {
    margin: 0 10px;
    padding: 10px 20px;
    border: 2px solid #ffffff;
    border-radius: 23px;
}

/* matrimony-card-end */

/* footer-start */
.lfooter h5::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 100%;
    margin-top: 15px;
    left: 0;
    border-bottom: 2px solid #b3b3b3;

}


/* footer-end */

@media(max-width:768px) {
    .sliderbg {
        height: auto;
    }
}

@media(max-width:576px) {}

@media(max-width:425px) {

    .headerbtn1,
    .headerbtn2 {
        padding: 5px 10px;
    }
}