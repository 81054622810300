.settingCards {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;
    overflow: hidden;
    margin-bottom: 10px;
}

.settingCardHeader {
    background: #E8E8E8 !important;
    border-bottom: none !important;
    padding: 20px !important;
}