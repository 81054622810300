.loginlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  border-radius: 50%;
  height: 80.11px;
  width: 79px;
}

.accounttype {
  width: 65.02px;
  height: 65.02px;
  margin: auto;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(255, 100, 138, 0.64);
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.7;
}

.selectedaccounttype{
  width: 65.02px;
  height: 65.02px;
  margin: auto;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0px 2px 5px rgba(255, 100, 138, 0.64);
  border-radius: 50%;
}

/*Stage 12 */
.userProfileImage {
  position: relative;
}

.userProfileImage img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  padding: 3px;
  background-color: white;
}

.userProfileImage svg {
  position: absolute;
  bottom: 5px;
  right: 8px;
  /* background: white; */
  border-radius: 50%;
}

.addPhotoBtn {
  background: linear-gradient(90deg, #ff648a 0%, #f0003b 103.48%);
  border-radius: 12px;
  color: #ffffff;
  padding: 18px 20px;
  border: none;
  width: 374px;
  height: 60px;
}

/* .addPhotoBtnText {
  display: block;
} */

.addGalleryBtnText {
  display: none;
}

.addtakePhotoBtn {
  display: none;
}

.closeButton {
  padding: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  /* .addPhotoBtnText {
    display: none;
  } */

  .addGalleryBtnText svg path {
    stroke: #ffffff;
  }

  .addGalleryBtnText {
    display: block;
  }

  .addtakePhotoBtn {
    background: linear-gradient(90deg, #989898 0%, #757575 103.48%);
    border-radius: 12px;
    color: #ffffff;
    padding: 20px 20px;
    border: none;
    width: 374px;
    height: 60px;
    display: block;
  }
}

@media (max-width: 400px) {
  .addPhotoBtn {
    padding: 5px 5px;
    width: 450px;
    height: 60px;
  }

  .addtakePhotoBtn {
    padding: 5px 5px;
    width: 450px;
    height: 60px;
  }
}

/*Stage 12 */